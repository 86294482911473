import * as React from "react"
import parse from 'html-react-parser'

import {Link, useStaticQuery, graphql} from 'gatsby'
import Layout from '../../components/layout/layout'
import Blogpost from "../../components/blogpost/blogpost"
import Adsense from "../../components/adsense/adsense";


const DetailPage = ({pageContext}) => {
    const {post} = pageContext
    var pageTitle = ''
    if (post) {
        pageTitle = `VR2WOA: ${post.title_en}`
    }

    return (
        <Layout pageTitle={pageTitle}>

                            <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto" />

            <div className="container">
                <div className="row ">
                    <div className="col-12">

                        {post ? (
                            <div
                                key={post.id}
                            >
                                <h3 className="pt-4 pb-4"><Link to={`/blog/${post.slug}/`}>{post.title_en}</Link></h3>

                                <p>{(new Date(post.creation_date_timestamp * 1000)).toLocaleDateString()}</p>

                                <p>{parse(post.content_en)}</p>


                            </div>

                        ) : null}


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default DetailPage
